import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Table, Button, Spinner, Alert, Modal, Form } from 'react-bootstrap';
import Topbar_Home from './Topbar-Home';

const FileList = ({ token }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [apiUrl, setApiUrl] = useState('');  // New state for API URL
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState('mp4');
  const [selectedResolution, setSelectedResolution] = useState('720p');

  // Fetch the API URL from the server
  useEffect(() => {
    const fetchApiUrl = async () => {
      try {
        const { data } = await axios.get('/api/config');
        setApiUrl(data.apiUrl);  // Set the API URL fetched from server
      } catch (error) {
        console.error('Error fetching API URL:', error);
      }
    };

    fetchApiUrl();
  }, []);

  useEffect(() => {
    if (apiUrl) {  // Only fetch files if API URL is set
      const fetchFiles = async () => {
        try {
          const response = await axios.get(`${apiUrl}/files`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setFiles(response.data);
        } catch (error) {
          console.error('Error fetching files:', error);
          setMessage('Failed to load files.');
        } finally {
          setLoading(false);
        }
      };

      fetchFiles();
    }
  }, [token, apiUrl]);

  const handleDownload = async (fileId) => {
    try {
      const { data } = await axios.get(`${apiUrl}/download/${fileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.open(data.downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
      setMessage('Failed to download the file.');
    }
  };

  const handlePlay = async (fileId) => {
    try {
      const { data } = await axios.get(`${apiUrl}/play/${fileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.open(data.playUrl);
    } catch (error) {
      console.error('Error playing file:', error);
      setMessage('Failed to play the video.');
    }
  };

  const handleDelete = async (fileId) => {
    try {
      await axios.delete(`${apiUrl}/delete/${fileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFiles(files.filter(file => file.videoId !== fileId));
      setMessage('File deleted successfully.');
    } catch (error) {
      console.error('Error deleting file:', error);
      setMessage('Failed to delete the file.');
    }
  };

  const handleTranscodeClick = (fileId) => {
    setSelectedFileId(fileId);
    setShowModal(true);  // Show the modal when the Transcode button is clicked
  };

  const handleTranscode = async () => {
    try {
      const response = await axios.post(`${apiUrl}/transcode/${selectedFileId}`, {
        format: selectedFormat,
        resolution: selectedResolution,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessage('Transcoding completed!');
      setShowModal(false);  // Hide the modal after submitting
    } catch (error) {
      console.error('Error transcoding file:', error);
      setMessage('Failed to transcode the file.');
    }
  };

  return (
    <div>
      <Topbar_Home /> {/* Logout handled in the Topbar */}
      <Container className="mt-4">
        <h2 className="text-center">Your Videos</h2>
        {message && <Alert variant="danger">{message}</Alert>}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Status</th>
                <th>Upload Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {files.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">No files available.</td>
                </tr>
              ) : (
                files.map((file) => (
                  <tr key={file.videoId}>
                    <td>{file.fileName}</td>
                    <td>{file.status}</td>
                    <td>{new Date(file.createdAt).toLocaleString()}</td>
                    <td>
                      <Button variant="outline-primary" onClick={() => handlePlay(file.videoId)}>Play</Button>{' '}
                      <Button variant="outline-success" onClick={() => handleDownload(file.videoId)}>Download</Button>{' '}
                      <Button variant="outline-info" onClick={() => handleTranscodeClick(file.videoId)}>Transcode</Button>{' '}
                      <Button variant="outline-danger" onClick={() => handleDelete(file.videoId)}>Delete</Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}

        {/* Modal for Transcode Options */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Select Transcode Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formatSelect">
                <Form.Label>Select Format</Form.Label>
                <Form.Control as="select" value={selectedFormat} onChange={(e) => setSelectedFormat(e.target.value)}>
                  <option value="mp4">MP4</option>
                  <option value="avi">AVI</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="resolutionSelect">
                <Form.Label>Select Resolution</Form.Label>
                <Form.Control as="select" value={selectedResolution} onChange={(e) => setSelectedResolution(e.target.value)}>
                  <option value="1080p">1080p</option>
                  <option value="720p">720p</option>
                  <option value="480p">480p</option>
                  <option value="360p">360p</option>
                  <option value="240p">240p</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleTranscode}>
              Transcode
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default FileList;
