// Login.js
import React, { useState } from "react";
import { CognitoIdentityProviderClient, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import { useNavigate } from "react-router-dom";
import Topbar_Login from '../components/Topbar-Login';
import './Login.css';

const client = new CognitoIdentityProviderClient({ region: "ap-southeast-2" });

const Login = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const command = new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: "37tjdi0fothi7dkjlqntaqdgrd", // Replace with your Cognito App Client ID
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password
      }
    });

    try {
      const response = await client.send(command);

      // Use the ID Token instead of Access Token
      const idToken = response.AuthenticationResult.IdToken;  // Retrieve ID Token
      setToken(idToken);  // Store ID Token
      alert('Login successful!');
      navigate('/upload'); // Redirect to upload page after successful login
    } catch (error) {
      console.error('Error logging in:', error);
      alert(error.message || 'Login failed.');
    }
  };

  return (
    <div>
      <Topbar_Login/>
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="login-title">Sign In</h2>
          <input
            className="login-input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            className="login-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="login-btn" type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
