// Signup.js
import React, { useState } from 'react';
import { CognitoIdentityProviderClient, SignUpCommand, ConfirmSignUpCommand } from "@aws-sdk/client-cognito-identity-provider";
import Topbar_Login from '../components/Topbar-Login';
import './Signup.css';

// Set up Cognito client
const client = new CognitoIdentityProviderClient({ region: "ap-southeast-2" });

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Sign-up function
  const handleSignup = async (e) => {
    e.preventDefault();
    const command = new SignUpCommand({
      ClientId: "37tjdi0fothi7dkjlqntaqdgrd", // Replace with your Cognito App Client ID
      Username: username,
      Password: password,
      UserAttributes: [{ Name: "email", Value: email }],
    });

    try {
      await client.send(command);
      setShowConfirmation(true);
      alert('Sign-up successful! Please check your email for the confirmation code.');
    } catch (error) {
      console.error('Error signing up:', error);
      alert(error.message || 'Sign-up failed.');
    }
  };

  // Confirm sign-up function
  const handleConfirmSignup = async (e) => {
    e.preventDefault();
    const command = new ConfirmSignUpCommand({
      ClientId: "37tjdi0fothi7dkjlqntaqdgrd",
      Username: username,
      ConfirmationCode: confirmationCode
    });

    try {
      await client.send(command);
      alert('Confirmation successful! You can now log in.');
    } catch (error) {
      console.error('Error confirming sign-up:', error);
      alert(error.message || 'Confirmation failed.');
    }
  };

  return (
    <div>
      <Topbar_Login/>
      <div className="signup-container">
        <form className="signup-form" onSubmit={showConfirmation ? handleConfirmSignup : handleSignup}>
          <h2 className="signup-title">{showConfirmation ? 'Confirm Signup' : 'Sign Up'}</h2>

          {!showConfirmation && (
            <>
              <input
                className="signup-input"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                className="signup-input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                className="signup-input"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button className="signup-btn" type="submit">Sign Up</button>
            </>
          )}

          {showConfirmation && (
            <>
              <input
                className="signup-input"
                type="text"
                placeholder="Confirmation Code"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
              />
              <button className="signup-btn" type="submit">Confirm</button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Signup;
